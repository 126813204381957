import { type FC, memo, type ReactNode } from 'react';
import type { CenterCardData } from '@repo/api-client';
import {
  convertCmToM,
  renderCenterDistance,
} from '../../utils';
import Link from 'next/link';
import { FilledButton } from '../button/filled-button';
import { ShadowLink } from '../shadow-link';
import { Cube, MapPin } from '@phosphor-icons/react/dist/ssr';
import { CenterCardImage } from './center-card-image';
import { convertNumberToDisplayablePriceString, formatCenterUrl } from '@repo/utils';

type CenterCardProps = {
  showSecondaryBtn?: boolean;
  secondaryBtn?: ReactNode;
  center: CenterCardData;
  className?: string;
  showCenterImage?: boolean;
  showFooter?: boolean,
  expand?: boolean;
};

const CenterCard: FC<CenterCardProps> = memo(function ({
  showSecondaryBtn = true,
  secondaryBtn,
  center,
  showCenterImage = false,
  expand = false,
  className = '',
  showFooter = true
}) {
  const centerUrl = formatCenterUrl(center.path);
  const isImageShown = showCenterImage && center.thumbnailMedia !== undefined;

  return (
    <div
      className={`flex flex-col justify-between overflow-hidden rounded-xl bg-white text-black shadow-lg ${className}`}>
      {/* Image */}
      {isImageShown && (
        <ShadowLink href={centerUrl}>
          <CenterCardImage image={center.thumbnailMedia} />
        </ShadowLink>
      )}

      <div
        className={`p-4 lg:p-6 ${
          isImageShown ? '!pt-4 lg:!pt-6' : ''
        } space-y-4 lg:space-y-6`}>
        {/* Heading */}
        <header className={`mb-2 flex flex-row items-start justify-between ${expand ? '' : 'lg:min-h-[52px]'}`}>
          <h3 className={`flex-1 pr-4 text-xl font-semibold text-brand`}>
            <Link href={centerUrl} className={'flex-1'}>
              {!expand ? (
                <>
                  {center.town} ({center.address.zip})
                </>
              ) : center.town}
            </Link>
          </h3>

          {center.distance !== undefined && (
            <p className={`text-right text-sm font-bold`}>
              à {renderCenterDistance(center.distance)}
            </p>
          )}
        </header>

        {/* Center address */}
        <div className="flew-row my-4 flex items-start justify-between gap-2">
          <MapPin size={20} weight="bold" />

          {/* Address */}
          <div className="flex-1 overflow-hidden">
            {/* Full address */}
            {expand && (
              <ul>
                <li>{center.address.line1}</li>
                {center.address.line2 && <li>{center.address.line2}</li>}
                {center.address.line3 && <li>{center.address.line3}</li>}
                <li>
                  {center.address.zip} - {center.address.city}
                </li>
              </ul>
            )}

            {/* Nearby big city */}
            {(!expand || center.bigCity) && (
              <p className={`leading-normal`}>
                {center.bigCity ? (
                  <>
                    Près de <b>{center.bigCity}</b>
                  </>
                ) : (
                  <>
                    {center.address.zip} - {center.address.city}
                  </>
                )}
              </p>
            )}
          </div>
        </div>

        {/* Computed infos */}
        <div className={`flew-row flex min-h-[48px] items-start gap-2`}>
          {center.computedInfos?.minSurface &&
            center.computedInfos?.maxSurface && (
              <>
                <Cube size={20} weight="bold" />

                <div className="flex-1">
                  <p className="leading-normal">
                    Boxes de{' '}
                    <b>
                      {convertCmToM(center.computedInfos.minSurface)} à{' '}
                      {convertCmToM(center.computedInfos.maxSurface)}m²
                    </b>
                  </p>
                  {center.computedInfos.minPrice && (
                    <p className="leading-normal">
                      Dès{' '}
                      <b>
                        {convertNumberToDisplayablePriceString(center.computedInfos.minPrice.allTaxesIncluded!, 0)}€
                        <span className='text-xs'>/mois</span>
                      </b>
                    </p>
                  )}
                </div>
              </>
            )}
        </div>

        {/* Footer */}
        {showFooter && <footer className="!mt-12 flex flex-row-reverse items-center justify-between">
          <ShadowLink href={centerUrl}>
            <FilledButton intent="secondary" className={'grow-0 px-10'}>
              Voir les tarifs
            </FilledButton>
          </ShadowLink>

          {showSecondaryBtn && secondaryBtn && secondaryBtn}
        </footer>}
      </div>
    </div>
  );
});

CenterCard.displayName = 'CenterCard';
export { CenterCard };
