import type { MediaEntity } from '@repo/api-client';
import { type FC, memo } from 'react';
import { SelfstockMedia } from '../media/selfstock-media';

type Props = {
  image?: MediaEntity;
  sizes?: string;
  className?: string;
  priority?: boolean;
};

const CenterCardImage: FC<Props> = memo(function ({
  image,
  priority = false,
  sizes,
  className,
}) {
  if (!image) 
    return <div className={className} />

  return (
    <SelfstockMedia
      media={image}
      className={`${className ?? ''} aspect-video bg-slate-100 object-cover object-center`}
      priority={priority}
      sizes={sizes}
      width={480}
      height={270}
    />
  );
});

CenterCardImage.displayName = 'CenterCardImage';
export { CenterCardImage };
