import { type CenterCardData } from "@repo/api-client";
import mitt, {type Emitter} from "mitt";
import { createContext, type FC, type ReactNode, useContext, useMemo } from "react";

type Events = {
  mapMarkerClicked: CenterCardData,
  cardClicked : CenterCardData,
};

const Context = createContext<Emitter<Events> | null>(null);

type Props = {
  children: ReactNode
}

export const StoreLocatorEventListenerProvider: FC<Props> = function ({children}) {
  const emitter = useMemo(() => mitt<Events>(), []);
  return <Context.Provider value={emitter}>
    {children}
  </Context.Provider>
}

export const useStoreLocatorEvents = () => {
  const events = useContext(Context)
  if (!events) throw new Error('Missing <StoreLocatorEventListenerProvider> in the tree');
  return events;
};