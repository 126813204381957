export function isScreenXS () {
  if (typeof window === "undefined")
    return false;

  return window.innerWidth <= 640;
}

export function isScreenSM () {
  if (typeof window === "undefined")
    return false;

  return window.innerWidth > 640;
}

export function isScreenMD () {
  if (typeof window === "undefined")
    return false;

  return window.innerWidth > 768;
}

export function isScreenLG () {
  if (typeof window === "undefined")
    return false;

  return window.innerWidth > 1024;
}

export function isScreenXL () {
  if (typeof window === "undefined")
    return false;

  return window.innerWidth > 1280;
}

export function isScreen2XL () {
  if (typeof window === "undefined")
    return false;

  return window.innerWidth > 1536;
}

export function isScreenSize(size : 'SM' | 'MD' | 'LG' | 'XL' | '2XL') : boolean {
  if (typeof window === "undefined") return false;

  switch(size) {
    case 'SM' :
      return window.innerWidth > 640;
   case 'MD' :
      return window.innerWidth > 768;
    case 'LG' :
      return window.innerWidth > 1024;
    case 'XL' :
      return window.innerWidth > 1280;
    case '2XL' :
      return window.innerWidth > 1536;
  }
}


export function useTailwindBreakpoints() {
  return {
    isXS: isScreenXS,
    isSM: isScreenSM,
    isMD: isScreenMD,
    isLG: isScreenLG,
    isXL: isScreenXL,
    is2XL: isScreen2XL,
  }
}