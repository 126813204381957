import { cva, type VariantProps } from "class-variance-authority";
import { type FC, type ReactNode } from "react";

const frameStyles = cva(
  'my-8 rounded-xl p-3 shadow-xl',
  {
    variants: {
      intent: {
        brand: 'bg-brand',
        white: 'bg-white',
      }
    },
    defaultVariants: {
      intent: 'brand'
    }
  }
);

interface Props extends VariantProps<typeof frameStyles> {
  className?:string,
  children: ReactNode
}

export const StoreLocatorSearchFormFrame: FC<Props> = function ({className = '', children, ...props}) {
  return <div className={frameStyles({className, ...props})}>
    {children}
  </div>
};