"use client";

import { useRouter } from "next/navigation";
import { type FC, type MouseEventHandler, type ReactNode, useCallback } from "react";

type Props = {
  href: string,
  as?: keyof JSX.IntrinsicElements,
  children: ReactNode,
  className?: string,
}

/**
 * Allow to wrap an element in a shadow link. 
 * It will behave just like a real link but won't contain <A> element
 * to improve SEO
 */
export const ShadowLink:FC<Props> = function ({href, children, className, as: Wrapper = "div"}) {
  const router = useRouter();

  const handleClick: MouseEventHandler = useCallback((event) => {
    // Handle mouse middle click button
    if (event.button === 1 && typeof window !== "undefined") {
      window.open(href, '_blank');
    } 
    // Handle regular click
    else {
      router.push(href)
    }
  }, [href, router]);

  return <Wrapper onClick={handleClick} onAuxClick={handleClick} className={className}>
    {children}
  </Wrapper>
};