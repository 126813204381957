import { type CenterCardData } from '@repo/api-client';
import { formatCenterName } from '@repo/utils'
import { useSearchParams } from 'next/navigation';
import {
  useStaticStoreLocator,
  useStoreLocator,
} from '@/utils/stores/use-store-locator';
import {
  type FC,
  memo,
  type RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { GoogleMap } from '../google-maps/google-map';
import { type GpsPosition } from '../google-maps/types';
import { MapController, ZoomLevels } from '../google-maps/utils/map-controller';
import { useSmoothScroll } from '../hooks/use-smooth-scroll';
import { isScreenSize } from '../hooks/use-tailwind-breakpoints';
import { useStoreLocatorEvents } from './store-locator-event-listener-provider';
import { StoreLocatorMapCenterModal } from './store-locator-map-center-modal';

type Props = {
  center?: GpsPosition;
  distance?: number;
  contained?: boolean;
  lazyLoad?: boolean;
  showCardsFooter?: boolean;
};

const StoreLocatorGoogleMap: FC<Props> = memo(function (props) {
  const store = useStaticStoreLocator();
  const setDisplayMode = useStoreLocator((state) => state.setDisplayMode);
  const controllerRef = useRef(new MapController());
  const events = useStoreLocatorEvents();
  const searchParams = useSearchParams();

  // Scrolls the page to put the map in the middle of the screen
  const _scrollTopMapEl = useSmoothScroll('#store-locator-map-wrapper');
  const scrollToMap = useCallback(() => {
    setTimeout(() => {
      if (typeof window !== 'undefined') _scrollTopMapEl();
    }, 100);
  }, [_scrollTopMapEl]);

  useEffect(() => {
    if (searchParams && searchParams.has('scrollToMap')) scrollToMap();
  }, [scrollToMap, searchParams]);

  // Opens the center modal overlay above the map when a map marker is clicked
  const onMarkerClick = useCallback(
    (centerData: CenterCardData) => {
      const controller = controllerRef.current;
      events.emit('mapMarkerClicked', centerData);

      if (store.getState().displayMode === 'MAP') scrollToMap();

      // Focus the marker on the map
      controller.setCenter({ lat: centerData.gpsLat, lng: centerData.gpsLng });
      setTimeout(() => controller.setZoom(ZoomLevels.centerMarker), 100);

      // Animate the marker
      controller.highlightMarker(centerData.id, 1_400);
    },
    [events, scrollToMap, store],
  );

  // Triggered when center list is updated to render all map markers
  const onCenterListUpdated = useCallback(
    function (centers?: Readonly<CenterCardData[]>) {
      const controller = controllerRef.current;
      // Clear previous map markers
      controller.clearAllMarkers();

      if (!centers) return;

      // Add map markers
      for (let i = centers.length - 1; i >= 0; i--) {
        controller.addMarker({
          id: centers[i].id,
          position: {
            lat: centers[i].gpsLat,
            lng: centers[i].gpsLng,
          },
          title: formatCenterName(centers[i]),
          onClick: () => onMarkerClick(centers[i]),
        });
      }

      controller.fitMarkersBounds();
    },
    [onMarkerClick],
  );

  // Sets the MapController on the StoreLocatorController
  const onMapLoaded = useCallback(
    async function (map: RefObject<google.maps.Map>) {
      const controller = controllerRef.current;
      controller.setMap(map.current!);

      // Populate initial markers
      onCenterListUpdated(store.getState().centers);

      // Handle initial center focus
      const query = store.getState().query;
      if (query.gpsLat && query.gpsLng) {
        const position = {
          lat: query.gpsLat,
          lng: query.gpsLng,
        };

        controller.setCenter(position);

        if (query.distance) {
          controller.setCircle({
            center: position,
            radiusKM: query.distance,
          });
        }
      }
    },
    [onCenterListUpdated, store],
  );

  const Map = useMemo(() => {
    const options = {
      center: props.center,
      defaultZoom: 7,
    };
    return (
      <GoogleMap
        mapSettings={options}
        onMapLoaded={onMapLoaded}
        contained={props.contained}
        lazyLoad={props.lazyLoad}
      />
    );
  }, [onMapLoaded, props.center, props.contained, props.lazyLoad]);

  // Updates the map center and radius circle on query change
  useEffect(
    () =>
      store.subscribe(
        (state) => state.query,
        (query) => {
          const controller = controllerRef.current;

          if (query.gpsLat && query.gpsLng) {
            const position = {
              lat: query.gpsLat,
              lng: query.gpsLng,
            };

            controller.setCenter(position);

            if (query.distance) {
              controller.setCircle({
                center: position,
                radiusKM: query.distance,
                animate: true,
              });
            } else {
              // Zoom the map
              controller.setZoom(ZoomLevels.centerMarker);
            }
          }
        },
      ),
    [store],
  );

  // Handle Centers Updates
  useEffect(
    () =>
      store.subscribe(
        (state) => state.centers,
        (centers) => onCenterListUpdated(centers),
        {
          equalityFn: (a, b) => {
            const result =
              Array.isArray(a) &&
              Array.isArray(b) &&
              a.length === b.length &&
              a.every((v, i) => v.id === b[i].id);

            return result;
          },
        },
      ),
    [onCenterListUpdated, store],
  );

  useEffect(() => {
    const onCardClicked = (center: CenterCardData) => {
      const { displayMode } = store.getState();
      if (displayMode !== 'MAP') {
        if (isScreenSize('XL')) {
          setDisplayMode('MIXT');
        } else {
          setDisplayMode('MAP');
          scrollToMap();
        }
      }

      const controller = controllerRef.current;
      controller.setCenter({ lat: center.gpsLat, lng: center.gpsLng });
      setTimeout(() => controller.setZoom(ZoomLevels.centerMarker), 200);
      controller.highlightMarker(center.id, 1_400);
    };

    events.on('cardClicked', onCardClicked);
    return () => {
      events.off('cardClicked', onCardClicked);
    };
  }, [events, scrollToMap, setDisplayMode, store]);

  return (
    <>
      {/* Center card overlay */}
      <StoreLocatorMapCenterModal showFooter={props.showCardsFooter} />

      {/* Map */}
      {Map}
    </>
  );
});

StoreLocatorGoogleMap.displayName = 'StoreLocatorGoogleMap';
export { StoreLocatorGoogleMap };
