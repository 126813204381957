import { useCallback } from 'react';

export function useSmoothScroll(elementSelector: string) {
  return useCallback(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') return;

    const el = document.querySelector(elementSelector);
    if (!el) return;

    const y =
      window.scrollY +
      el.getBoundingClientRect().top -
      (document.querySelector('#main-menu-container')?.getBoundingClientRect()
        .height ?? 0);

    window.scrollTo({
      top: y,
      behavior: 'smooth',
    });
  }, [elementSelector]);
}
