import { type FC, memo, type RefObject, useCallback, useEffect, useRef } from "react";


export type GoogleMapsProps = {
  mapSettings: {
    center?: {lng: number, lat: number},
    defaultZoom?: number,
  },
  onMapLoaded: (map: RefObject<google.maps.Map>) => Promise<void>,
  contained?: boolean;
  lazyLoad?: boolean
};

const GoogleMap: FC<GoogleMapsProps> = memo(function ({mapSettings, contained, onMapLoaded, lazyLoad = true}) {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<google.maps.Map | null>(null);


  const loadMap = useCallback(async () => {
    const mapOptions: google.maps.MapOptions = {
      center: {
        lat: mapSettings.center?.lat ?? 48.866667,
        lng: mapSettings.center?.lng ?? 2.333333,
      },
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      styles: await import('./assets/google-maps-theme.json'),
      zoom: mapSettings.defaultZoom ?? 12,
    };

    if (!mapContainerRef.current) throw new Error('ERROR : there is no mapContainerRef defined');
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

    // Create the map
    mapRef.current = new Map(
      mapContainerRef.current!, 
      mapOptions
    );

    // Execute callback
    onMapLoaded(mapRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Connect observer to load the map when it enters into the viewport
  useEffect(() => {
    if (!IntersectionObserver || !mapContainerRef.current) return;

    if (!lazyLoad) {
      loadMap().then();
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMap().then();
        observer.disconnect();
      }
    }, {});

    observer.observe(mapContainerRef.current);
    return () => { observer.disconnect() };
  });

  // Map
  const output = <div ref={mapContainerRef} className={'h-full'} />;

  if (!contained) return output;

  return <div className="relative overflow-hidden">{output}</div>;

});

GoogleMap.displayName = 'GoogleMap';
export { GoogleMap };