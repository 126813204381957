import { type AnimeParams } from "animejs";
import { type GpsPosition } from "../types";
import { type AddMarkerParams, type DrawCircleParams } from "./types";

export const ZoomLevels = {
  centerMarker: 12,
  country: 7,
  region: 9,
  department: 10,
}

const maxDefaultZoom = ZoomLevels.centerMarker;

export class MapController {

  private rangeCircleCenterMarker?:google.maps.Marker;
  private rangeCircle?: google.maps.Circle;
  private markers: Record<string, google.maps.Marker> = {};
  private map?: google.maps.Map
  
  constructor(
  ) {}
  
  setMap(map: google.maps.Map) {
    // if (this.map !== undefined) throw new Error('Map is already defined for this controller');
  
  // Prevents map to overzoom
  this.map = map;
  google.maps.event.addListenerOnce(map, "bounds_changed", () => {
    this.map!.setZoom(Math.min(this.map!.getZoom() ?? 0, maxDefaultZoom));
  });
}


  /**
   * Adds a marker on the map 
   * 
   * @param params 
   * @returns 
   */
  addMarker(params: AddMarkerParams) {
    const marker = new google.maps.Marker({
      map: this.map,
      title: params.title,
      // animation: google.maps.Animation.DROP,
      position: params.position,
      clickable: true,
      icon: {
        url: '/map-marker.svg',
        scaledSize: new google.maps.Size(50, 50),
      },
    });

    this.markers[params.id] = marker;
  
    if (!params.onClick) 
      return;
  
    marker.addListener("click", params.onClick);
  }

  getMarker(id: string) : google.maps.Marker | undefined {
    return this.markers[id];
  }

  highlightMarker(id: string, durationMS?: number) {
    const marker = this.getMarker(id);
    if (!marker)
      return;
    
    marker.setAnimation(google.maps.Animation.BOUNCE);
    marker.setIcon({
      url: '/map-marker.svg',
      scaledSize: new google.maps.Size(50, 50),
      scale: 1.5
    });

    if (durationMS) setTimeout(() => this.resetMarker(id), durationMS);
  }

  resetMarker(id: string) {
    const marker = this.getMarker(id);
    if (!marker)
      return;
    
    marker.setAnimation(null);
    marker.setIcon({
      url: '/map-marker.svg',
      scaledSize: new google.maps.Size(50, 50),
      scale: 1
    });
  }

  clearAllMarkers() {
    const markerArray = Object.values(this.markers);

    for (const marker of markerArray) {
      marker.setMap(null);
    }

    this.markers = {};
  }

  fitMarkersBounds() {
    const bounds = new google.maps.LatLngBounds();
    const markerArray = Object.values(this.markers);

    for (const marker of markerArray) {
      const pos = marker.getPosition();
      if (!pos) continue;
      bounds.extend(pos);
    }

    this.fitBounds(bounds);
  }

  fitBounds(bounds: google.maps.LatLngBounds | null) {
    if (this.map === undefined) throw new Error('There is no Map defined for this controller');

    if (bounds !== null) 
      this.map.fitBounds(bounds, 20);
  }

  setCircle(params: DrawCircleParams) {
    // Creates a diamond marker positioned on the search coords
    if (!this.rangeCircleCenterMarker) {
      this.rangeCircleCenterMarker = new google.maps.Marker({
        position: new google.maps.LatLng({
          lat: params.center.lat,
          lng: params.center.lng
        }),
        icon: {
          path: 'M -2,0 0,-2 2,0 0,2 z',
          strokeColor: '#f7a700',
          fillColor: '#f7a700',
          fillOpacity: 1,
          scale: 2
        },
      });
    } else {
      if (this.map === undefined) throw new Error('There is no Map defined for this controller');
      this.rangeCircleCenterMarker.setMap(this.map);
      this.rangeCircleCenterMarker.setPosition(new google.maps.LatLng({
        lat: params.center.lat,
        lng: params.center.lng
      }));
    }

    // Bind the marker to the map
    if (this.map !== undefined)
      this.rangeCircleCenterMarker.setMap(this.map);

    // Draw a circle representing the distance radius
    const finalCircleRadius = params.radiusKM * 1000;
    if (!this.rangeCircle) {
      this.rangeCircle = new google.maps.Circle({
        map: this.map,
        radius: params.animate ? 0 : finalCircleRadius,
        fillColor: '#f7a700',
        fillOpacity: 0.1,
        strokeColor: '#f7a700',
        strokeWeight: 1,
        clickable: false,
      });
  
      // Binds the circle to the user point
      this.rangeCircle.bindTo('center', this.rangeCircleCenterMarker, 'position');
    } else {
      if (this.map === undefined) throw new Error('There is no Map defined for this controller');
      this.rangeCircle.setRadius(params.animate ? 0 : finalCircleRadius);
      this.rangeCircle.setMap(this.map);
    }

    if (params.animate) {
      import('animejs').then((module) => {
        module.default({
          targets: {value: 0},
          value: finalCircleRadius,
          duration: 1000,
          delay: 500,
          easing: "easeOutBack",
          update: (params:AnimeParams) => {
            this.rangeCircle?.setRadius(params.animations[0].currentValue);
          },
          complete: () => {
            this.fitBounds( this.rangeCircle?.getBounds() ?? null );
          }
        });
      });
    } else {
      this.fitBounds( this.rangeCircle.getBounds() );
    }
  }

  removeCircle(animate?: boolean) {
    if (!this.rangeCircle || ! this.rangeCircleCenterMarker)
      return;
    
    this.rangeCircleCenterMarker.setMap(null);
    if (!animate) {
      this.rangeCircle.setMap(null);
    } else {
      if (animate) {
        import('animejs').then((module) => {
          module.default({
            targets: {value: this.rangeCircle!.getRadius()},
            value: 0,
            duration: 500,
            delay: 500,
            easing: "easeInBack",
            update: (params:AnimeParams) => {
              this.rangeCircle?.setRadius(params.animations[0].currentValue);
            }
          });
        });
      }
    }
  }

  setCenter(center: GpsPosition) {
    if (this.map === undefined) throw new Error('There is no Map defined for this controller');
    this.map.panTo(center);
  }

  setZoom(level: number) {
    if (this.map === undefined) throw new Error('There is no Map defined for this controller');
    this.map.setZoom(level);
  }
}